import React from 'react';
import PropTypes from 'prop-types';

const FooterLogos = ({ logos = [] }) => (
  <div className="footer-logos">
    <ul>
      {logos.map((item) => (
        <li key={item.logo.url}>
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-logos--link"
          >
            <img src={item.logo.url} alt={item.logo.alt} width={80} height={60} loading="lazy" />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

FooterLogos.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      logo: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      link: PropTypes.string,
    }),
  ),
};

export default FooterLogos;
